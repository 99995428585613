import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const Terms = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <Link to={closeTo} className="modalcloselink">X</Link>
        ) : (
          <header>  &nbsp;
          </header>
        )}

        <h2>Terms</h2>

        
        <strong>Last Updated November 6, 2019</strong>
        <p>
The Jukin Media, Inc. ("Jukin") community is based on the idea that video creators should be
compensated for the licensing of their compelling videos, and that industry professionals should
have a simple process for selecting the videos that they want to license for their programming.
Subject to our Terms of Service, Jukin is a hassle-free resource for entertainment industry
professionals looking for content.
          </p>

          <h3> AGREEMENT TO TERMS OF SERVICE </h3>
<p>The policies below are applicable to all Jukin owned or operated brands and web sites, including
but not limited to, the web sites located at www.JukinMedia.com, ​ www.thepetcollective.tv​ ,
www.failarmy.com, www.jukinvideo.com, www.peopleareawesome.com,
www.dailypicksandflicks.com, www.thisishappeningshow.com, www.pokemyheart.tv, and each
of their respective affiliates, parents, subsidiaries, licensees, websites, and assigns (collectively
hereinafter referred to, alternatively, as “Jukin Media,” the “Site,” “we,” “us,” “our” or similar
identifying pronouns). Please read these Terms of Service carefully to ensure your legal, safe,
and enjoyable use of the Site. They contain the legal terms and conditions that govern your use
of the services provided to you by the Site and are a binding agreement with you, just as if you
had signed this document. In using the Site, you affirm that you are at least 18 years of age, or an
emancipated minor, or possess legal parental or guardian consent, and you are fully able and
competent to enter into, abide by and comply with the terms, conditions, obligations,
affirmations, representations, and warranties set forth in these Terms of Service.</p>

<h3> LICENSE TO USE SITE </h3>
<p>As long as you comply with these Terms of Service, Jukin Media grants you a personal,
non-exclusive, non-transferable, limited license to enter and use the Site and the services
provided. This license is for the sole purpose of enabling you to use and enjoy the benefits of the
services Jukin Media provides, subject to and in accordance with these Terms of Service. You
may not copy, modify, distribute, sell or lease any part of our services or our Site or its software,
nor may you reverse engineer or attempt to extract the source code or source hyperlinks of that
software, nor may you use any information contained within the Site to independently approach
third parties. Jukin Media reserves the right, at its discretion, to modify, change, remove, update
or add to these Terms of Service at any time without notice. It is your responsibility and
obligation to review these Terms of Service periodically for changes. Unless otherwise specified,
such changes shall be effective when they are posted, and your continued use of the Site
following the posting of changes will mean that you accept and agree to the changes. Your
access or use of the Site constitutes your agreement to be bound by these Terms of Service, theJukin Media licensing terms (as applicable) and the terms and conditions of our Privacy Policy,
as posted on this Site and which are incorporated herein by reference, including any changes that
exist when you re-access this Site (whether or not you have chosen to register as a member of the
Site). ​ BY USING THE SITE, YOU AGREE TO THESE TERMS OF SERVICE; IF YOU
DO NOT AGREE WITH THE TERMS OF THIS AGREEMENT, THE JUKIN MEDIA
LICENSING TERMS, THE PRIVACY POLICY OR ANY OTHER GUIDELINES OR
RULES POSTED ON THE SITE, THEN DO NOT USE THIS SITE. </p>

<h3>OWNERSHIP OF SITE; SITE CONTENT</h3>
<p>
All text, software, user interfaces, visual interfaces, graphics, photographs, artwork, trademarks,
logos, sounds, music, video, computer codes and other audio-visual material (collectively,
“Content”) contained on the Site, including but not limited to the design, structure, coordination,
selection, expression, and arrangement of such Content, is controlled, owned, or licensed by or to
Jukin Media, as further set forth in these Terms of Service, and is protected by copyright,
trademark and various other intellectual property and unfair competition laws.
Except as expressly provided in these Terms of Service, no part of the Site and no Content may
be used in any way without Jukin Media’s express prior written consent, including without
limitation the copying, publicly display, reproduction, republishing, posting, uploading,
transmitting, or distributing in any method or manner to any other device, computer, web site or
other platform or medium. The unauthorized copying and distribution (including without
limitation downloading, uploading, file serving, file "swapping" or other similar activities) of
any such Content or materials constitutes copyright infringement under the U.S. Copyright Act
and international copyright laws, and Jukin Media and its third party licensors and suppliers will
enforce such rights to the full extent of the law. Violation of these terms shall be assumed to
have been willful. </p> 
<p>Jukin Media and other Jukin Media graphics, logos and service names are registered or otherwise
protected trademarks or service marks (“Trademarks”). These Trademarks many not be used in
connection with any product or service that is not owned or operated by Jukin Media or in any
manner that is likely to cause confusion among customers or in any other manner that disparages
or discredits Jukin Media. All other trademarks, branding and logos not owned by Jukin Media
that appear on this Site are the property of their respective owners who may or may not be
affiliated with, sponsored by or connected to Jukin Media, and Jukin Media makes no warranty
as to these other trademarks, branding and logos.</p>
<p>Jukin Media reserves the right to do any of the following, at any time, without notice: (1)
modify, terminate, or suspend access/operation to the Site, or any portion of the Site, for any
reason; (2) modify or change the Site, or any portion of the Site, and any applicable policies or
terms; and (3) interrupt the operation of the Site, or any portion of the Site, as necessary to
perform routine or non-routine maintenance, error correction, technical updates or other changes.PRIVACY
Our Privacy Policy details how your information is used when you use our Services. By using
our Services, you’re also agreeing that we can process your information in ways set out in the
Privacy Policy, so please read it ​<a href="/privacypolicy">here​</a> .</p>
<h3> USER CONDUCT </h3>
<p>You agree that all the information and Content downloaded, viewed, screened or accessed will
be used only for informational and/or evaluation purposes. You acknowledge and agree that you
may be charged customary and reasonable fees for downloading Content, separate to and
regardless of whether you actually license the Content. ​ YOU AGREE THERE SHALL BE NO
COMMERCIAL EXPLOITATION OR USE, DIRECTLY OR INDIRECTLY, OR ANY
OTHER UNAUTHORIZED USE (WHETHER COMMERCIAL OR
NON-COMMERCIAL) OF ANY CONTENT AVAILABLE ON THIS SITE WITHOUT A
SEPARATE FULLY EXECUTED LICENSE AGREEMENT BY AND BETWEEN JUKIN
MEDIA AND YOU AUTHORIZING SAME.​ In case of circumvention, you agree and
acknowledge that a breach of this Terms of Service agreement by you would cause Jukin Media
irrevocable injury and damage that cannot be adequately compensated by damages in an action at
law. You therefore expressly agree that, without limiting Jukin Media’s rights and remedies at
law, including without limitation the right to seek damages for any such breach by you, Jukin
Media shall further be entitled to injunctive and other equitable relief to prevent and/or cure any
breach or threatened breach of this agreement, without proof of actual damages. In addition, you
agree to indemnify and hold Jukin Media harmless from and against any and all claims,
damages, liabilities, costs and expenses, including reasonable outside attorney's fees, arising out
of or resulting from your unauthorized use of any Content on the Site (including, but not limited
to, your editing thereof).</p>
<h3>RESTRICTION ON USE OF SITE</h3>
<p>You may not use the Site, or any Content, to obtain or attempt to obtain any documents,
materials, videos, sound bites or information through any means not purposely made available or
designed through the Site. You may not attempt to gain unauthorized access, by hacking,
password “mining” or any other illegitimate means to any portion or feature of the Site, or any
other systems or networks connected to the Site, or to any of the services offered on or through
the Site. You may not scan, probe or test the vulnerability of the Site or any network connected
to the Site, nor breach the security or authentication measures on the Site or any network
connected to the Site. You may not trace, seek, or reverse look-up any information on any other
user of or visitor to the Site, or exploit the Site or any service or information made available or
offered by or through the Site, in any manner where the purpose is to reveal information,
including personal identification or information, other than your own as provided for by the Site
and its Privacy Policy. You agree that you will not take any action that imposes an unreasonable
or disproportionately large load on the infrastructure of the Site. You agree not to use any device,
software or routine to interfere or attempt to interfere with the proper working of the Site, or with
any other person’s use of the Site. You may not use the Site or any Content for any purpose thatis unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal
activity or other activity which infringes the rights of Jukin Media, its suppliers or others.
Posting of unsolicited advertisements or repeat bulk-style communications anywhere on the Site
is prohibited. Using our Site does not give you ownership of any intellectual property rights in
our Site or the Content you access. You may not use Content from our Site unless you obtain
permission from Jukin Media or are otherwise permitted by law.</p>
<h3>MONITORING</h3>
<p>Use of the Internet subjects parties to a certain amount of inherent risk. Jukin Media has no
obligation to monitor the use of the Site or to verify, warrant, or vouch for the accuracy and
quality of the information that users may acquire. Internet users must exercise their own best
judgment in relying on information obtained from the Internet, including the Site. Jukin Media
bears no responsibility for information or communications downloaded, posted or otherwise
added to its Site by third parties, regardless of how inaccurate, unsuitable, offensive, or illegal.
Notwithstanding the foregoing, Jukin Media reserves the right to, and may from time to time,
monitor any and all information transmitted or received through the Site for operational and
other purposes. During monitoring, information may be examined, recorded, copied, and used
for authorized purposes in accordance with our Privacy Policy. It is your responsibility to
familiarize yourself with our Privacy Policy. Use of the Site constitutes consent to such
monitoring and use. We further reserve the right at all times to remove any information or
materials, in whole or in part, that in our sole and absolute discretion are objectionable or in
violation of these Terms of Service and/or our Privacy Policy. </p>
<h3>COPYRIGHT INFRINGEMENT; DIGITAL MILLENNIUM COPYRIGHT ACT</h3>
<p>Jukin Media does not permit copyright infringement on the Site and will remove any infringing
content if we receive notice that such content infringes on another's intellectual property rights.
Jukin Media further reserves the right to terminate accounts or access to the Site in the case of
repeat infringers.</p>
<p>If you are a copyright owner or an agent thereof and believe that any content on the Site infringes
upon your copyrights, you may submit a notification pursuant to the Digital Millennium
Copyright Act ("DMCA") by providing our Copyright Agent with the following information in
writing (see 17 U.S.C 512(c)(3) for further detail):
§ The physical or electronic signature of a person authorized to act on behalf of the owner of an
exclusive right that is allegedly infringed;
§ Identification of the copyrighted work claimed to have been infringed, or, if multiple
copyrighted works at a single online site are covered by a single notification, a representative list
of such works at that site;§ Identification of the material that is claimed to be infringing or to be the subject of infringing
activity and that is to be removed or access to which is to be disabled and information reasonably
sufficient to permit the service provider to locate the material;
§ Information reasonably sufficient to permit the service provider to contact you, such as an
address, telephone number, and, if available, an electronic mail;
§ A statement that you have a good faith belief that use of the material in the manner complained
of is not authorized by the copyright owner, its agent, or the law; and
§ A statement that the information in the notification is accurate, and under penalty of perjury,
that you are authorized to act on behalf of the owner of an exclusive right that is allegedly
infringed.</p>
<p>If you fail to comply with all of the requirements set forth herein, your DMCA notice may not be
valid. Notice can be submitted to Jukin Media’s designated Copyright Agent
at DMCAAgent@JukinMedia.com.</p>
<p>If a counter-notice is received by the Copyright Agent, Jukin Media may send a copy of the
counter-notice to the original complaining party informing that person that we may replace the
removed content or cease disabling it in 10 business days. Unless the copyright owner files an
action seeking a court order against the content provider, member or user, the removed content
may be replaced or access to it restored in 10 to 14 business days or more after receipt of the
counter-notice, at Jukin Media’s sole discretion.
DISCLAIMER OF WARRANTIES
WE SOMETIMES PROVIDE LINKS TO INTERNET SITES MAINTAINED BY THIRD
PARTIES ("THIRD PARTY SITES") AND MAY FROM TIME TO TIME PROVIDE
THIRD PARTY MATERIALS ON THIS SITE. NEITHER THIS SITE, JUKIN MEDIA,
NOR ITS AFFILIATES OR SUPPLIERS NOR ANY OF THEIR RESPECTIVE
DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS, OPERATE, CONTROL OR
ENDORSE IN ANY RESPECT ANY INFORMATION, PRODUCTS OR SERVICES ON
THESE THIRD PARTY SITES. THIS SITE, THE MATERIALS AND PRODUCTS
AVAILABLE IN OR ACCESSIBLE THROUGH THIS SITE, AND THE THIRD PARTY
SITES ARE PROVIDED "AS IS" AND, TO THE FULLEST EXTENT PERMISSIBLE
PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
NON-INFRINGEMENT, AND WARRANTIES IMPLIED FROM A COURSE OF
PERFORMANCE OR COURSE OF DEALING. WE DO NOT WARRANT THAT THE
FUNCTIONS CONTAINED IN SUCH MATERIALS AND PRODUCTS, OR IN THE
SERVICE, WILL BE UNINTERRUPTED OR ERROR-FREE, WILL BE AVAILABLE
FOR USE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE,
INCLUDING STORAGE SERVICES AND THEIR CONTENTS, OR THE SERVERTHAT MAKES THEM AVAILABLE, ARE FREE OF VIRUSES OR OTHER
HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY
REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF THE USE, OF
THE MATERIALS IN THE SERVICE OR IN THIRD PARTY SITES IN TERMS OF
THEIR CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY,
COMPLETENESS OR OTHERWISE.</p>
<h3>LIMITATION OF LIABILITY</h3>
<p>Your communications with, business dealings with, or participation in promotions of merchants
found on or through the Site, including payment and delivery of related goods or services, and
any other terms, conditions, warranties, or representations associated with such dealings, are
solely between you and such merchant. You understand and agree that any content downloaded
or otherwise obtained through the use of this Site is done at your own risk and that you will be
solely responsible for any damage done to your computer system or loss of data that results from
the download of such content. We will not be responsible or liable for any loss or damage of any
sort incurred as the result of any such dealings, content downloads or as the result of the presence
of such merchants on the Site. You assume all risk of errors and/or omissions on the Site and any
Third Party Sites, including the transmission or translation of information. You assume full
responsibility for implementing sufficient procedures and checks to satisfy your requirements for
the accuracy and suitability of the Site and any Third Party Sites, including the information
contained therein, and for maintaining any means that you may require for the reconstruction of
lost data or subsequent manipulations or analyses of the information provided hereunder. You
acknowledge and agree that your use of the Site and any Third Party Sites, and any information
sent or received in connection therewith, may not be secure and may be intercepted by
unauthorized parties. ​ YOU ASSUME RESPONSIBILITY FOR THE ENTIRE COST OF
ALL NECESSARY MAINTENANCE, REPAIR OR CORRECTION TO YOUR
COMPUTER SYSTEM OR OTHER PROPERTY.</p>
<p>In no event shall the Site, Jukin Media, its parent, affiliates or subsidiary companies or their
respective directors, officers, employees, agents, affiliates or suppliers be liable for any direct,
indirect, punitive, incidental, special, consequential or other damages arising out of or in any way
connected with the use of the Site or with the delay or inability to use the Site, or for any
information, software, products and services advertised in or obtained through the Site, our
removal or deletion of any materials or records submitted or posted on the Site, or otherwise
arising out of the use of the Site, whether based on contract, tort, strict liability or otherwise,
even if Jukin Media, or any of its subsidiary companies, affiliates or suppliers has been advised
of the possibility of damages. This waiver applies, without limitation, to any damages or injury
arising from any failure of performance, error, omission, interruption, deletion, defect,
incomplete ownership or invalid license, delay in operation or transmission, computer virus, file
corruption, communication-line failure, network or system outage, theft, destruction,
unauthorized access to, alteration of, or use of any record. You specifically acknowledge and
agree that Jukin Media, its parent or subsidiary companies, affiliates or suppliers shall not be
liable for any defamatory, offensive or illegal conduct of any user of the service. While some
states do not allow limitations on how long an implied warranty lasts or the exclusion orlimitation of incidental or consequential damages, you are consenting by these Terms of Service
to personal jurisdiction in California.</p>
<h3>INDEMNIFICATION</h3>
<p>You agree to defend, indemnify and hold harmless the Site, Jukin Media and its parent, affiliates
or subsidiary companies and their respective directors, officers, employees and agents from and
against any and all claims, actions, suits or proceedings, as well as any and all losses, liabilities,
damages, costs and expenses (including reasonable attorneys fees) arising out of or accruing
from (i) your access to or use of this Site; (ii) your violation of these Terms of Service (including
without limitation the Licensed Rights or any License Agreement); (iii) your violation of any
third party right, including without limitation any intellectual property right, property or privacy
right; or (iv) any claim that your use of the site or its Content caused damage to you or a third
party. You agree that we may at any time and without notice, suspend or terminate your access to
this Site if you fail to comply with these Terms of Service or applicable law.</p>
<h3>CHILD PROTECTION NOTIFICATION</h3>
<p>This Site contains some content that might be deemed unsuitable to minors. This Site is intended
for a mature audience and parents who find any material on this site unsuitable for their children
are encouraged to utilize parental control services. Parental control protections (such as computer
hardware, software, or filtering services) are commercially available that may assist you in
limiting access to material that is harmful to minors.</p>
<h3>VIOLATION OF THESE TERMS OF SERVICE</h3>
<p>You may use our Site only as permitted by law. As further set forth in our Privacy Policy, you
agree and acknowledge that Jukin Media may preserve any communication and information
made or disclosed by you through the Site, and Jukin Media reserves the right to disclose any
such communication or information that Jukin Media deems necessary (i) to comply with any
applicable law, regulation, legal process or governmental authority; (ii) to enforce these Terms of
Service; (iii) to respond to claims that any such data violates the rights of others; or (iv) to
protect the rights, property or personal safety of Jukin Media, its affiliates, parent, subsidiaries,
suppliers and their respective directors, officers, employees and agents and/or users of or visitors
to the Site, and the public.</p>
<p>You also agree that Jukin Media may, in its sole discretion and without prior notice, suspend or
terminate your access to the Site and/or block your future access to the Site if we determine that
you have violated these Terms of Service or other agreements or guidelines which may be
associated with your use of the Site. You also agree that any violation by you of these Terms of
Service will constitute an unlawful and unfair business practice and will cause irreparable harm
to Jukin Media for which monetary damages would be inadequate, and you consent to Jukin
Media obtaining any injunctive or equitable relief that Jukin Media or its counsel deemsnecessary in such circumstances. These remedies are in addition to any other remedies Jukin
Media may have at law or in equity.</p>
<p>If Jukin Media does take any legal action against you as a result of your violation of these Terms
of Service, Jukin Media will be entitled to recover from you, and you agree to pay, all attorneys’
fees and costs of such action, in addition to any other relief granted. You agree that Jukin Media
will not be liable to you or to any third party for termination of your access to the Site as a result
of any violation of these Terms of Service or as a result of technical problems or modifications to
the Site or as otherwise required by law.</p>
<h3>ASSIGNMENT</h3>
<p>These Terms of Service and any rights and licenses granted by you hereunder (including without
limitation the Licensed Rights) may not be transferred or assigned by you, but may be assigned
by Jukin Media without restriction.</p>
<h3>GOVERNING LAW; DISPUTE RESOLUTION</h3>
<p>You agree that: (i) the Site and all services provided shall be deemed solely based in California;
and (ii) the Site and all services provided shall be deemed a passive website that does not give
rise to personal jurisdiction over Jukin Media, its parent, affiliates or subsidiary companies and
their respective directors, officers, employees and agents, in jurisdictions other than California.
You agree these Terms of Service shall be governed by the internal substantive laws of the State
of California, without respect to its conflict of laws principles. Although you acknowledge that
we will have the ability to enforce our rights in any court of competent jurisdiction, you hereby
consent to the personal jurisdiction and exclusive venue in Los Angeles, California, U.S.A.,
regarding any and all disputes relating to these Terms of Service or your access to or use of the
Site, including without limitation use of Content. You acknowledge and agree that the warranty
disclaimers and liability and remedy limitations in this agreement are material terms of this
agreement and that they have been taken into account in the decision by us to provide the Site to
you hereunder. Except in instances where Jukin Media deems it necessary to seek injunctive or
other equitable relief (and as otherwise set forth in these Terms of Service), the parties hereby
agree to submit any disputes or controversies between them to binding arbitration in Los
Angeles, California in accordance with the rules of the American Arbitration Association. All
rights to recover consequential, incidental and/or punitive damages are waived by you. In the
event of any dispute, you shall not be entitled to and hereby waive all right to any equitable relief
whatsoever, including the right to rescind this Terms of Service agreement , or to enjoin, restrain
or interfere in any manner with the Site. The prevailing party shall be entitled to its reasonable
outside attorneys’ fees and costs, including its share of the arbitration costs, from the losing
party. All decisions of the arbitrator shall be final, binding, and conclusive on all parties.
Judgment may be entered upon any such decision in accordance with applicable law in any court
having jurisdiction thereof.</p>
<p>YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
THE SITE OR THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTERTHE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CLAIM OR CAUSE OF
ACTION IS PERMANENTLY BARRED.</p>
<p>If any provision of these Terms of Service is deemed invalid by a court of competent
jurisdiction, the invalidity of such provision shall not affect the validity of the remaining
provisions of these Terms of Service, which shall remain in full force and effect. This agreement,
the Privacy Policy and any other posted operating rules constitute the entire agreement of the
parties with respect to the subject matter hereof, and supersede all prior or contemporaneous
communications and proposals, whether oral or written, between the parties with respect to such
subject matter. No waiver of any provision or any right granted hereunder will be effective
unless set forth in a written instrument signed by the waiving party and no waiver by either party
of any breach or default hereunder shall be deemed a waiver of any subsequent breach or default.</p>
      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default Terms